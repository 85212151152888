import { useIntl } from 'react-intl';
import React, { useCallback, useState, useMemo } from 'react';

// Own components
import { ProductsList } from '../../components/ProductsList';
import SectionCover from '../../components/SectionCover/sectionCover';

// Assets & Misc
import { productsFamily } from '../../constants/products';

const ProductsFilters = ({ categories, setSelectedFilter, selectedFilter }) => {
  if (categories.length === 0) {
    return null;
  }

  const { formatMessage } = useIntl();

  const handleFilterClick = useCallback((ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    setSelectedFilter(ev.target.getAttribute('data-filter'));
  }, []);

  const getActiveClass = useCallback((filter) => selectedFilter === filter ? 'active' : '', [selectedFilter])

  return (
    <div className="filter-section">
      <div className="col-sm-12 col-xs-12">
        <div className="filter-container isotopeFilters">
            <ul className="list-inline filter">
              <li className={getActiveClass('*')}>
                <a data-filter="*" onClick={handleFilterClick}>{formatMessage({ id: 'allCategories' })}</a>
              </li>
              {categories.map(category => {
                const id = `.${category.id}`;
                return (
                  <li key={category.id} className={`cat-item cat-item-2 ${getActiveClass(id)}`}>
                    <a data-filter={id} onClick={handleFilterClick}>{formatMessage({ id: category.title })}</a>
                  </li>
                );
              })}
            </ul>
        </div>
      </div>
    </div>
  );
}

const Products = ({ familyId }) => {
  const { formatMessage } = useIntl();
  const family = productsFamily[familyId];
  const [selectedFilter, setSelectedFilter] = useState('*');
  const selectedProducts = useMemo(() => {
    const products = [...family.subcategories, ...family.products.filter(product => !product.subcategory)];

    if (selectedFilter !== '*') {
      return products.filter(subcategory => `.${subcategory.category}` === selectedFilter);
    }

    return products;
  }, [selectedFilter, familyId]);

  return (
    <>
      <SectionCover title={formatMessage({ id: familyId })} src={`/images/products/${familyId}.jpeg`} />
      {/* Filters */}
      <ProductsFilters categories={family.categories} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
      {/* Products */}
      <ProductsList products={selectedProducts} style={{ marginTop: family.categories.length ? '0' : '3rem' }} />
    </>
  );
};

export default Products;