import React from 'react';

// Own components
import SEO from '../components/seo';
import Layout from '../components/Layout/es';
import Products from '../containers/Products';

// Assets & Misc
import { getQueryParam } from '../utils/getters';
import { navigateTo } from '../utils/navigation';
import { productsFamily } from '../constants/products';

const ProductsPage = () => {
  const familyId = getQueryParam('family');
  const product = Object.keys(productsFamily).find(id => id === familyId);

  if (!product) {
    navigateTo('/404');
    return null;
  }

  return (
    <Layout>
      <SEO title="Productos" />
      <Products familyId={familyId} />
    </Layout>
  );
};

export default ProductsPage;
